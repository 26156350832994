/* MessageFromFounders.css */







.founders-container {
    padding: 60px 20px;
    text-align: center;
    background: url('https://png.pngtree.com/background/20210716/original/pngtree-white-islamic-pattern-background-picture-image_1347311.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .founders-container h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .message-carousel .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .message-carousel .carousel .control-dots .dot {
    background: #555;
  }
  
  .message-carousel .carousel .control-dots .dot.selected {
    background: #000;
  }
  
  .message-carousel .founder-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .message-carousel .founder-card:hover {
    transform: translateY(-10px);
  }
  
  .message-carousel .founder-card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .message-carousel .founder-info h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .message-carousel .founder-info h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #777;
  }
  
  .message-carousel .founder-info p {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    padding: 0 10px;
  }


  @media(max-width:800px){
    .founders-container h2 {
        font-size: 1.3em;
      }
  }
  