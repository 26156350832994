.admin-nav{
    display: flex;
    box-shadow: 3px 3px 4px gray;
    margin-bottom: 20px;
}

.admin-nav a{
    margin: 10px;
    text-decoration: none;
    color: black;
}