/* AdminPage.css */

.admin-page {
    padding: 2rem;
    padding-top: 130px;
  }
  
  .admin-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .admin-form label {
    margin-bottom: 1rem;
  }
  
  .admin-form input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .admin-form button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #00BF63;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .admin-form button:hover {
    background-color: #009b49;
  }
  