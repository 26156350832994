/* Follow Us Container */
.follow-us-container {
  padding: 40px 20px;
  text-align: center;
  color: #000000;
  background: url('/public/background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 200px;
}

/* Header Style */
.follow-us-container h2 {
  font-size: 2.5em; /* Larger font size */
  margin-bottom: 20px;
  color: #000000; /* White for better contrast on dark backgrounds */
  font-family: 'Arial', sans-serif; /* Clean sans-serif font */
}

/* Social Icons Container */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Social Icon Style */
.social-icons .icon {
  font-size: 2.5em; /* Larger icon size */
  color: #fff; /* White color for icons */
  transition: color 0.3s ease, transform 0.3s ease, background 0.3s ease;
  border: 3px solid #fff; /* White border for contrast */
  border-radius: 50%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2); /* Slightly transparent background */
}

/* Hover Effects for Social Icons */
.social-icons .icon:hover {
  color: #000; /* Darker color on hover */
  background: #fff; /* White background on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}

/* Specific Colors for Social Media Icons */
.icon.instagram {
  color: #e4405f; /* Instagram color */
}

.icon.facebook {
  color: #1877f2; /* Facebook color */
}

.icon.twitter{
  color: #1da1f2; /* Twitter color */
}

.icon.linkedin {
  color: #0a66c2; /* LinkedIn color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .follow-us-container {
      padding: 20px;
  }

  .follow-us-container h2 {
      font-size: 2em; /* Smaller font size on mobile */
  }

  .social-icons {
      flex-direction: column;
      gap: 15px;
  }

  .social-icons .icon {
      font-size: 2em; /* Smaller icon size on mobile */
      padding: 10px;
      border-radius: 10px; /* Less rounded corners on mobile */
  }
}
