.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  /* background: url('background.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.about-text {
  flex: 1;
  padding: 20px;
}

.about-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  padding-top: 70px;
}

.about-text p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.about-image img {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.collection-carousel {
  margin-bottom: 40px;
}

.collection-carousel h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.additional-sections section {
  margin-bottom: 40px;
}

.additional-sections h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.additional-sections p {
  font-size: 1rem;
  line-height: 1.6;
}

.carousel .slide img {
  max-height: 400px;
  object-fit: cover;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.022);
  border-radius: 50%;
}


@media(max-width:900px){
  .about-section {
    flex-direction: column;
  }

  .about-image {
    padding-top: 10px;
  }
}

.about-container {
  padding: 20px;
}

.about-section, .collection-carousel, .team-section, .additional-sections {
  margin-bottom: 40px;
}

.about-text {
  text-align: left;
}

.about-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.2em;
  line-height: 1.6;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 20px;
}

.collection-carousel img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.team-section {
  text-align: center;
}

.team-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.team-members {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.team-member {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 250px;
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 1em;
  color: #777;
}

