.our-story {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  padding-top: 100px;
  padding-bottom: 100px;
  background: url('https://media.istockphoto.com/id/1393729215/photo/close-up-detail-of-old-watercolor-paper-texture-background-beige-paper-vintage-use-for-banner.webp?b=1&s=612x612&w=0&k=20&c=tzM7uW8fCRBrf31Olu8sntI9_Vr0mCYPHD5gABYT0fY=');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-story h2{
  font-size: 2rem;
  padding-bottom: 20px;
}

.our-story-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 2rem;
}

.story-text {
  flex: 1;
  font-size: 1.1rem;
  letter-spacing: 3px;
  word-spacing: 10px;
  line-height: 34px;
}

.story-carousel {
  flex: 1;
}

.cimage{
 min-height: 600px;
}

@media(max-width:900px){

  .cimage{
    min-height: 300px;
   }


   .our-story-content {
    max-width: 100%;
    flex-direction: column;
   }


}