.contact-container {
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    padding-top: 150px;
    background: url('/public/background.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 150px;
  }
  
  .contact-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-address {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .contact-address h2 {
    margin-bottom: 10px;
  }
  
  .contact-address p {
    margin: 5px 0;
  }
  

  @media(max-width:800px){
    .contact-container{
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 50px;
      padding-top: 80px;
    }
  }