/* MissionVision.css */

.mission-vision-container {
    padding: 60px 20px;
    background: url('/public/background.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .mission-vision-container section {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: rgb(195,134,34);
    background: linear-gradient(308deg, rgba(195, 133, 34, 0.418) 0%, rgba(253, 236, 45, 0.349) 100%);
    border-radius: 10px;
    overflow: hidden;
    width: 80%;
  }
  
  .mission-vision-container section:nth-child(even) .image-container {
    order: 2; /* Move the image to the right for even sections */
  }
  
  .mission-vision-container .image-container {
    max-width: 50%;
    margin: 0 20px;
  }
  
  .mission-vision-container .image-container img {
    width:350px;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .mission-vision-container .text-container {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  
  .mission-vision-container section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .mission-vision-container section p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .mission-vision-container section {
      flex-direction: column;
    }
  
    .mission-vision-container .image-container {
      max-width: 100%;
      margin: 0;
    }
  
    .mission-vision-container .text-container {
      text-align: center;
    }

      
  .mission-vision-container .image-container img {
    width:270px;
    height: 370px;
  }
  }
  

  @media(max-width:800px){
    .mission-vision-container section {
      margin-bottom: 60px;
      padding: 10px;
      width: 95%;
    }
  }