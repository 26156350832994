/* Testimonials.css */

.testimonials-container {
    padding: 60px 20px;
    text-align: center;
    margin: 0 auto;
    background: url('https://png.pngtree.com/background/20210716/original/pngtree-white-islamic-pattern-background-picture-image_1347311.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .testimonials-container  .carousel .slide img {
    width: 70%;
    vertical-align: top;
    border: 0;
}
  
  .testimonials-container h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333; /* Dark gray text */
    font-family: 'Arial', sans-serif;
  }
  
  .testimonial-carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial-image {
    border-radius: 50%;
    width: 40px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .testimonial-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-name {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #555; /* Medium gray */
  }
  
  .testimonial-message {
    font-size: 1.1em;
    color: #666; /* Slightly lighter gray */
    margin-bottom: 15px;
    line-height: 1.6;
    max-width: 600px;
  }
  
  .testimonial-rating {
    display: flex;
    justify-content: center;
  }
  
  .star-icon {
    color: #f4c542; /* Gold color for stars */
    font-size: 1.5em;
  }
  
  @media (max-width: 768px) {
    .testimonials-container {
      padding: 40px 15px;
    }
  
    .testimonials-container h2 {
      font-size: 2em;
    }
  
    .testimonial-card {
      padding: 15px;
    }
  
    .testimonial-name {
      font-size: 1.3em;
    }
  
    .testimonial-message {
      font-size: 1em;
    }
  
    .star-icon {
      font-size: 1.3em;
    }
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
}
  