.fashion {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding-top: 120px;
    background: #e3f2fd; /* Light blue background */
  }
  
  .fashion-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .fashion-container .fashion-item-card {
    width: 290px;
    margin: 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd; /* Light border */
  }
  
  .fashion-container .fashion-item-card img {
    width: 100%;
    border-radius: 5px;
    height: 200px;
    margin-bottom: 10px;
  }
  
  .fashion-container .fashion-item-info {
    text-align: center;
  }
  
  .fashion-container .fashion-item-info h3 {
    font-size: 1.2rem;
    color: #333; /* Darker text */
    margin-bottom: 5px;
  }
  
  .fashion-container .fashion-item-info p {
    font-size: 1rem;
    color: #777; /* Lighter text */
    margin-bottom: 10px;
  }
  
  .fashion-container .qty {
    margin: 10px;
    font-style: italic;
    color: #555; /* Medium text color */
  }
  
  .fashion-container button {
    margin-bottom: 5px;
  }
  
  .fashion-container .button-43,
  .fashion-container .button-44 {
    padding: 10px;
    background-color: #2196f3; /* Blue background */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .fashion-container .button-43:hover,
  .fashion-container .button-44:hover {
    background-color: #1976d2; /* Darker blue on hover */
  }
  
  .fashion-container .button-43 svg,
  .fashion-container .button-44 svg {
    margin-right: 5px;
  }
  