@import url('https://fonts.googleapis.com/css2?family=Changa:wght@200..800&family=Permanent+Marker&display=swap');

.homepage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    background: url('/public/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.header{
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage .header h1{
    font-size: 5rem;
    margin-right: 15px;
    color: black;
    text-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    font-family: "Changa", sans-serif;
    font-optical-sizing: auto;
    text-align: center;
    word-spacing: 0;
}

.homepage .header h1 span{
  color: #20a9a9;
  font-family: "Changa", sans-serif;
}

.header-description{
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    letter-spacing: 4px;
    font-style: italic;
}

.home-buttons{
    margin: 10px;
    text-decoration: none;
    margin-top: 50px;
}

.home-nav{
    margin-top: 20px;
}

.button-91 {
  color: #fff;
  padding: 15px 25px;
  background-color: #38D2D2;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  font-weight: bold;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}

.button-91:hover{
    background-image: radial-gradient(93% 87% at 87% 89%, rgba(177, 177, 177, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.424) 0%, rgba(113, 113, 113, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
}

/* Scroll Down Icon */
.scroll-down {
    position: relative;
    width: 24px;
    height: 24px;
    text-decoration: none;
  }
  
  .chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
  }
  
  .chevron:first-child {
    animation: move 3s ease-out 1s infinite;
  }
  
  .chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
  }
  
  .chevron:before,
  .chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background-color: #38D2D2;
  }
  
  .chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
  }
  
  .chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
  }
  
  @keyframes move {
    25% {
      opacity: 1;
  
    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
  }
  
  .scroll-down .text {
    display: block;
    margin-top: 75px;
    margin-left: -42px;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    font-size: 12px;
    color: #000000;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: .25;
    animation: pulse 2s linear alternate infinite;
    text-align: center;
  }
  
  @keyframes pulse {
    to {
      opacity: 1;
    }
  }


  @media(max-width:900px){
    .header{
      display: flex;
      flex-direction: column;
    }
    .homepage .header h1{
      font-size: 1.8rem;
      margin: 0px;
      letter-spacing: normal;
    }

    .header img{
      width: 300px;
    }

    .header-description{
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center;
      font-size: 0.9rem;
    }
  }