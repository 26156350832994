/* ProductPage.css */

/* General Styles */
.product-page {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    padding-top: 120px;
  }
  
  .add-product-btn {
    padding: 12px 24px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-bottom: 40px;
  }
  
  .add-product-btn:hover {
    background-color: #218838;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .form-container {
    background: white;
    padding: 30px;
    border-radius: 12px;
    width: 500px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .close-btn:hover {
    background: #c82333;
  }
  
  h2 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
  }
  
  label {
    display: block;
    margin-bottom: 12px;
    color: #555;
    font-size: 16px;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 10px;
    margin-top: 6px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  input[type="file"] {
    border: none;
  }
  
  textarea {
    height: 100px;
    resize: vertical;
  }
  
  button[type="submit"] {
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .product-list {
    margin-top: 30px;
  }
  
  .product-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-page  .product-image {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
  }
  
  .product-info {
    flex-grow: 1;
  }
  
  .product-info h3 {
    margin-top: 0;
    font-size: 20px;
    color: #333;
  }
  
  .product-info p {
    margin: 5px 0;
    color: #666;
  }
  
  .edit-btn,
  .delete-btn {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-right: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .edit-btn:hover {
    background: #0056b3;
  }
  
  .delete-btn {
    background: #dc3545;
  }
  
  .delete-btn:hover {
    background: #c82333;
  }




.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
  }
  
  .login-form label {
    margin-bottom: 10px;
  }
  
  .login-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-form button {
    background-color: #34d399;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .login-form button:hover {
    background-color: #2b9a6e;
  }


  .logout-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    float: right;
  }
  
  .logout-btn:hover {
    background-color: #d32f2f;
  }