/* Footer Container */
.footer {
  background-color: #1a1a1a;
  color: #fff;
  padding: 40px 0;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* Footer Content */
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Footer Links */
.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.footer-links li {
  display: inline;
  margin: 0 15px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #f39c12;
}

/* Footer Info */
.footer-info {
  margin-bottom: 20px;
}

.footer-info p {
  margin: 5px 0;
  font-size: 14px;
}

.footer-info a {
  color: #f39c12;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-info a:hover {
  color: #fff;
}

/* Social Icons */
.social-icons {
  margin-top: 20px;
}

.social-icons p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.social-icons a {
  color: #fff;
  margin: 0 10px;
  font-size: 44px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #f39c12;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      text-align: center;
  }

  .footer-links li {
      display: block;
      margin: 10px 0;
  }

  .social-icons a {
      font-size: 20px;
  }

    /* Footer Container */
    .footer .social-icons{
      flex-wrap: wrap;
    }

}

  /* Footer Container */
  .footer .social-icons{
    display:flex;
    justify-content: center;
    align-items: center;
  }
