/* ProductDetails.css */

.product-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    min-height: 100vh;
    box-sizing: border-box;
  }
  
  .product-details-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-width: 800px;
    width: 100%;
    margin: 1rem;
    box-sizing: border-box;
  }
  
  .product-image-container {
    width: 100%;
    overflow: hidden;
  }
  
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .product-image-container:hover .product-image {
    transform: scale(1.1);
  }
  
  .product-info-container {
    padding: 1.5rem;
    text-align: left;
  }
  
  .product-name {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .product-price {
    font-size: 1.5rem;
    color: #00BF63;
    margin-bottom: 1rem;
  }
  
  .product-description,
  .product-qty {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .product-details  .button-22 {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #00BF63;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .product-details  .button-22:hover {
    background-color: #009b49;
  }
  