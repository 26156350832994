@import url('https://fonts.googleapis.com/css2?family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.categories {
    padding: 20px;
    padding-top: 150px;
    padding-bottom: 150px;
    background: url('/public/background.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Edu AU VIC WA NT Hand", cursive;
}

.categories-heading {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.category-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding-left: 200px;
    padding-right: 200px;
}

.category-card {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 3px 10px 18px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(128, 128, 128, 0.164);
}

.category-card img {
    width: 100%;
    height: 250px;
    transition: transform 0.3s ease;
}

.category-card a{
    color: black;
    text-decoration: none;
}

.category-card h3 {
    text-align: center;
    padding: 10px;
    letter-spacing: 5px;
    font-family: "Edu AU VIC WA NT Hand", cursive;
}

.category-card:hover img {
    transform: scale(1.1);
}


@media(max-width:900px){
    .category-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}
