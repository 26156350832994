.home-decor{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    padding-top: 120px;
    background: #b5bdac7c;
}

.home-decor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .home-decor-container .decor-item-card {
    width: 290px;
    margin: 20px;
    box-shadow: 3px 3px 10px rgba(119, 52, 0, 0.156);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.372);
    border: none;
  }
  .home-decor-container button{
    margin-bottom: 5px;
  }

  .home-decor-container .qty{
    margin: 10px;
    font-style: italic;
  }
  
  .home-decor-container .decor-item-image {
    width: 100%;
    border-radius: 5px;
    height: 200px;
    margin-bottom: 10px;
  }
  
  .home-decor-container .decor-item-info {
    text-align: center;
  }
  
  .home-decor-container .add-to-cart-btn {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .home-decor-container .add-to-cart-btn:hover {
    background-color: #0056b3;
  }
  
  .home-decor-container .add-to-cart-btn svg {
    margin-right: 5px;
  }
  

